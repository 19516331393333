<template>
  <v-dialog
      v-model="modalAddPosition"
      width="500"
      persistent
      @close="setModalAddPosition(false)"
      transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title style="font-weight: 300;font-size: 18px;" class="title-header-form ml-5">
          {{ $t("Employee.form.position") }}
        </v-list-item-title>
        <v-btn
            icon
            depressed
            @click="setModalAddPosition(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <div class="from-content">
        <div class="from">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="data.name"
                    outlined
                    label="Position Name "
                    :hint="`${server_errors.name}`"
                    persistent-hint
                    dense>
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                  Save Change
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      modalAddPosition: false,
      listCurrency: [],
      btnLoading: false,
      data: {
        name: "",
      },
      server_errors: {
        name: ""
      }
    }
  },

  methods: {
    setModalAddPosition(isVisible) {
      this.modalAddPosition = isVisible;
      this.$emit('change', isVisible);
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      this.btnLoading = true;
      this.$axios.post(`company/position`, this.data).then((res) => {
        if (res.data.code === 200) {
          this.btnLoading = false;
          setTimeout(() => {
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.resetForm();
          this.$emit("selectCurrentPosition", res.data.code)
          this.setModalAddPosition(false)
        }
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      })

    },
  },
  watch: {
    visible(val) {
      this.modalAddPosition = val;
    },
  },
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  height: 250px;

  .from {
    width: 100%;
    height: 250px;
    padding: 20px 40px;
    margin-top: 60px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4A4A4A;
}
</style>
