<template>
  <v-dialog
    v-model="modalAddEmployeeLevel"
    width="500"
    persistent
    @close="setModalAddEmployeeLevel(false)"
    transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title
          style="font-size: 18px; font-weight: 300"
          class="title-header-form ml-5"
        >
          {{ $t("Employee.form.addEmployeeLevel") }}
        </v-list-item-title>
        <v-btn icon depressed @click="setModalAddEmployeeLevel(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <div class="from-content">
        <div class="from">
          <v-form ref="form" @submit.prevent="ValidateForm">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="data.name"
                  outlined
                  :label="$t('Employee.form.addEmployeeLevel')"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-btn class="btn-save-change" type="submit">
                  {{ $t("Employee.form.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
    districtId: {},
  },
  data() {
    return {
      modalAddEmployeeLevel: false,
      data: {
        name: "",
      },
    };
  },

  methods: {
    setModalAddEmployeeLevel(isVisible) {
      this.modalAddEmployeeLevel = isVisible;
      this.$emit("change", isVisible);
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      const item = {
        name: this.data.name,
      };
      this.$axios
        .post(`company/add/employee/level`, item)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.resetForm();
            this.$emit("success");
            this.$emit("selectCurrentEmployeeLevel", res.status);
            this.setModalAddEmployeeLevel(false);
          }
        })
        .catch(() => {});
    },
  },
  watch: {
    visible(val) {
      this.modalAddEmployeeLevel = val;
    },
  },
};
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  height: 200px;
  padding-top: 20px;
  overflow: hidden;

  .from {
    width: 100%;
    height: 200px;
    padding: 20px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}
</style>
