<template>
  <v-dialog
      v-model="modalAddBranch"
      width="600"
      persistent
      @close="setModalAddBranch(false)"
      transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title style="font-size: 18px;font-weight: 300" class="title-header-form ml-5">
          {{ $t("Employee.form.addBranch") }}
        </v-list-item-title>
        <v-btn
            icon
            depressed
            @click="setModalAddBranch(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <div class="from-content">
        <v-form class="form" ref="form">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  dense
                  class="label-input"
                  :label="$t('branch.branchName')"
                  :rules="nameRules"
                  v-model="items.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint

              >
              </v-text-field>
            </v-col>

            <v-col cols="4" md="6">
              <v-select
                  :label="$t('branch.province')"
                  dense
                  outlined
                  v-model="province_id"
                  :items="provinces"
                  item-text="name"
                  :rules="nameRules"
                  :error-messages="`${server_errors.province_id}`"
                  required
                  item-value="id">
              </v-select>
            </v-col>
            <v-col cols="4" md="6">
              <v-select
                  :label="$t('branch.district')"
                  dense
                  outlined
                  v-model="items.district_id"
                  :rules="nameRules"
                  required
                  :items="districts"
                  :error-messages="`${server_errors.district_id}`"
                  item-text="name"
                  item-value="id">
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  v-model="items.village"
                  dense
                  class="label-input"
                  :label="$t('branch.village')"
                  :error-messages="`${server_errors.village}`"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  outlined
                  dense
                  v-model="items.description"
                  :label="$t('branch.description')"
                  :error-messages="`${server_errors.description}`"
              >

              </v-textarea>
            </v-col>

            <v-col cols="8">
              <v-btn class="btn-save-change" @click="validateForm" :loading="btnLoading">
                {{ $t("branch.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
      type: String
    },
    districtId: {},
  },
  data() {
    return {
      modalAddBranch: false,
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      items: {
        name: "",
        district_id: "",
        village: "",
        description: ""
      },
      server_errors: {
        name: "",
        province_id: "",
        district_id: "",
        village: "",
        description: ""
      },
      provinces: [],
      districts: [],
      selectedProvince: {},
      selectedDistrict: {},
      filterDistricts: [],
      province_id: "",
    }
  },

  methods: {
    setModalAddBranch(isVisible) {
      this.modalAddBranch = isVisible;
      this.$emit('change', isVisible);
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    FetchDistrict() {
      this.$axios.get(`list/addresses`).then((res) => {
        const items = res.data;
        this.provinces = items.provinces;
        this.selectedProvince = {...this.provinces[0]};
        this.filterDistricts = items.districts;
      }).catch((error) => {
        console.log(error)
      })
    },
    FilterDistricts(provinceID) {
      this.districts = this.filterDistricts.filter(item => {
        if (item.province_id == provinceID) {
          return item;
        }
      });
    },
    saveChange() {
      this.btnLoading = true;
      this.$axios.post(`company/add/company/branch`, this.items).then((res) => {
        if (res) {
          this.btnLoading = false;
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.resetForm();
        this.$emit("selectCurrentBranch", res.status)
        this.setModalAddBranch(false)
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  watch: {
    visible(val) {
      this.modalAddBranch = val;
    },
    'province_id': function (provinceID) {
      this.FilterDistricts(provinceID);
    },
  },
  created() {
    this.FetchDistrict();
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 600px;
  height: 450px;
  padding: 40px 60px;
  overflow: hidden;

  .from {
    height: 450px;
    padding: 20px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4A4A4A;
}
</style>
